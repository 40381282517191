import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { ClientPortalRole } from 'src/types';

export const userSliceSelector = (state: RootState) => state.user;

export const clientPortalUserSelector = createSelector(userSliceSelector, (state) => state.clientPortalUser);

export const clientPortalUserIdSelector = createSelector(clientPortalUserSelector, (state) => state?.id || null);

export const clientPortalUserRoleSelector = createSelector(
  clientPortalUserSelector,
  (state) => state?.clientPortalRole?.roleName || null,
);

export const isViewerClientPortalRoleSelector = createSelector(
  clientPortalUserRoleSelector,
  (role) => role === ClientPortalRole.Viewer,
);

export const userPermissionsSelector = createSelector(userSliceSelector, (state) => state.userPermissions);

export const isClientPortalUserSshPublicKeyConfiguredSelector = createSelector(userSliceSelector, (state) =>
  Boolean(state.clientPortalUser?.userSshKey?.hasPublicKey),
);
