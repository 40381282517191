import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'src/store';
import {
  clientPortalUserRoleSelector,
  isClientPortalUserSshPublicKeyConfiguredSelector,
} from 'src/store/selectors/userSelector';

import { useForm } from 'react-hook-form';
import { object, string, ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormErrors } from '@itm/shared-frontend/lib/hooks';

import { SuccessModal } from '@itm/shared-frontend/lib/components/modals';
import { LoadingButton } from '@itm/shared-frontend/lib/components/buttons';
import { Field, ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';

import AuthLayout from 'src/components/layout/AuthLayout';

import { retrieveClientPortalUser } from 'src/context/Auth';
import { updateCurrentUserSshPublicKey } from 'src/api/clientPortal/ssh';

import { ServerError, UpdatePublicKeyRequest, ClientPortalRole } from 'src/types';

type FormData = UpdatePublicKeyRequest;

const formSchema: ObjectSchema<FormData> = object({
  publicKey: string()
    .label('Key')
    .trim()
    .required()
    .test(
      'ssh-rsa',
      ({ label }) => `${label} expected format is "ssh-rsa <Base64-encoded key>"`,
      (value) => value.startsWith('ssh-rsa '),
    ),
});

function ManageSSHPage() {
  const navigate = useNavigate();
  const clientPortalUserRole = useSelector(clientPortalUserRoleSelector);
  const isClientPortalUserSshPublicKeyConfigured = useSelector(isClientPortalUserSshPublicKeyConfiguredSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);

  const hasSftpOnlyRole = clientPortalUserRole === ClientPortalRole.SftpOnly;

  const { register, control, handleSubmit, formState, setError, clearErrors } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });

  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const closeModal = async () => {
    setIsLoading(true);
    await retrieveClientPortalUser();
    setIsShowSuccessModal(false);
    setIsLoading(false);

    if (!hasSftpOnlyRole) {
      navigate(-1);
    }
  };

  const onSubmit = handleSubmit(async (formData) => {
    handleErrors();
    try {
      await updateCurrentUserSshPublicKey(formData);
      setIsShowSuccessModal(true);
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  return (
    <AuthLayout withUserNav={hasSftpOnlyRole} isWide>
      <h1 className="is-size-2 has-text-centered mb-5">Manage SSH Key</h1>
      <p className="is-size-3 mb-3">
        You require to have <strong>SSH key set up</strong> as the form to successfully authenticate your account into
        DataHub's SFTP.
      </p>
      <p className="is-size-3 mb-5">
        You will need to generate the keys using the client SFTP application of your choice, and store the generated
        Public key here.
      </p>

      <form name="manageSshKeyForm" onSubmit={onSubmit} noValidate>
        <Field
          inputClassName="is-fullwidth"
          label="Key"
          field="textarea"
          placeholder="Begins with 'ssh-rsa'"
          autoComplete="off"
          register={register('publicKey')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          disabled={formState.isSubmitting}
        />

        <div className="level-right">
          {!hasSftpOnlyRole && (
            <button className="button is-grey is-outlined" type="button" onClick={() => navigate(-1)}>
              Cancel
            </button>
          )}
          <LoadingButton className="button is-interact ml-3" type="submit" isLoading={formState.isSubmitting}>
            {`${isClientPortalUserSshPublicKeyConfigured ? 'Update' : 'Add'} SSH key`}
          </LoadingButton>
        </div>
      </form>
      <ServerErrorMessages messages={serverErrorMessages} />

      <SuccessModal
        isShowModal={isShowSuccessModal}
        closeModal={closeModal}
        Body={
          <p className="is-size-3">
            SSH Key has been {isClientPortalUserSshPublicKeyConfigured ? 'updated' : 'added'} successfully.
          </p>
        }
        Footer={
          <LoadingButton className="button is-interact" onClick={closeModal} isLoading={isLoading}>
            Continue
          </LoadingButton>
        }
      />
    </AuthLayout>
  );
}

export default ManageSSHPage;
