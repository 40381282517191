import { useMemo, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AuthContext, logout } from 'src/context/Auth';

import { RoutePath } from 'src/router';

import { Converter } from '@itm/shared-frontend/lib/utils';
import { LogoutIconSVG, UserIcon } from '@itm/shared-frontend/lib/components/icons';

function UserNavbarItem() {
  const navigate = useNavigate();
  const { clientPortalUser, clientPortalUserRole } = useContext(AuthContext);

  const userName = useMemo(
    () => (clientPortalUser ? `${clientPortalUser.firstName} ${clientPortalUser.lastName}` : ''),
    [clientPortalUser],
  );

  const userRoleWithoutModuleName = useMemo(
    () =>
      clientPortalUserRole
        ? Converter.stripUpperToCapitalizedAll(clientPortalUserRole.slice(clientPortalUserRole.indexOf('_') + 1))
        : '',
    [clientPortalUserRole],
  );

  const logOutClickHandler = async () => {
    await logout();
    navigate(RoutePath.login);
  };

  return (
    <div className="navbar-brand">
      <div className="navbar-item dropdown is-hoverable is-right mx-5">
        <div className="dropdown-trigger">
          <a
            className="navbar-link has-icon is-arrowless has-text-grey-light px-2"
            role="button"
            aria-label={userName || 'User name'}
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            tabIndex={0}
          >
            <UserIcon />
            <div className="dropdown-item navbar-item is-flex-direction-column is-align-items-start is-hidden-mobile pl-5">
              <p>{userName}</p>
              <p className="has-text-grey">{userRoleWithoutModuleName}</p>
            </div>
          </a>
          <div className="dropdown-menu is-right" id="dropdown-menu" role="menu">
            <div className="dropdown-content pb-0">
              <div className="dropdown-item navbar-item is-flex-direction-column is-align-items-start is-hidden-tablet pl-5">
                <p>{userName}</p>
                <p className="has-text-grey">{userRoleWithoutModuleName}</p>
              </div>
              <hr className="dropdown-divider is-hidden-tablet mb-0" />
              <Link className="dropdown-item has-icon is-justify-content-flex-start pl-5" to={RoutePath.changePassword}>
                Change Password
              </Link>
              <Link className="dropdown-item has-icon is-justify-content-flex-start pl-5" to={RoutePath.changeMFA}>
                Change MFA
              </Link>
              <Link className="dropdown-item has-icon is-justify-content-flex-start pl-5" to={RoutePath.manageSshKey}>
                Manage SSH keys
              </Link>
              <hr className="dropdown-divider is-hidden-tablet my-0" />
              <button
                className="button logout-button has-icon dropdown-item is-justify-content-flex-start is-hidden-tablet pl-5"
                type="button"
                onClick={logOutClickHandler}
                role="menuitem"
              >
                <LogoutIconSVG /> <span className="pl-3">Log out</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-item is-right is-hidden-mobile mr-5">
        <button
          className="button logout-button has-icon px-4"
          type="button"
          onClick={logOutClickHandler}
          aria-label="Logout"
          title="Logout"
        >
          <LogoutIconSVG />
        </button>
      </div>
    </div>
  );
}

export default UserNavbarItem;
