import Cookie from 'js-cookie';
import { createContext, useState, useCallback, PropsWithChildren, useEffect } from 'react';
import { BroadcastChannel } from 'broadcast-channel';

import store, { useSelector, useDispatch, resetStore } from 'src/store';
import { setIdentityUser } from 'src/store/slices/authSlice';
import { setTenantBranding } from 'src/store/slices/tenantSlice';
import { setClientPortalUser, setUserPermissions } from 'src/store/slices/userSlice';
import { fetchProductInfoAction } from 'src/store/slices/productInfoSlice';
import { fetchCompaniesByUserIdAction, fetchCompaniesAllAction } from 'src/store/slices/companySlice';

import { clientPortalUserSelector, clientPortalUserRoleSelector } from 'src/store/selectors/userSelector';
import { isLoginJourneyFinishedSelector, dataGatewayUserRoleSelector } from 'src/store/selectors/authSelector';

import { clientPortalApi } from 'src/api';
import { getCurrentUser, activateCurrentUser } from 'src/api/clientPortal/user';
import { getPermissionsAssignedToUser } from 'src/api/clientPortal/permissions';

import { PageLoading, ActivityDetector } from '@itm/shared-frontend/lib/components';
import { tenantDomain, cookieAttributes } from '@itm/shared-frontend/lib/utils';
import {
  IdentityCommonService,
  applyTenantBranding,
  isTokenExpired,
  USER_AUTH_COOKIE_NAME,
} from '@itm/shared-frontend/lib/components/initial';

import SignInRedirect from 'src/components/SignInRedirect';

import { AUTH_API_URL } from 'src/utils/constants';

import { CurrentUserResponse, UserDto, ClientPortalRole, UserStatus, DataGatewayRole } from 'src/types';

type ClientPortalBroadcastMessage = {
  isSignIn?: boolean;
  isSignOut?: boolean;
};

export const clientPortalChannel = new BroadcastChannel<ClientPortalBroadcastMessage>('client-portal');

const REFRESH_TOKEN_CHECK_TIME = 30 * 1000; // 30 sec
const REFRESH_TOKEN_IN_ADVANCE_TIME = 1 * 60 * 1000; // 1 min - must be greater than REFRESH_TOKEN_CHECK_TIME

export const identity = new IdentityCommonService(AUTH_API_URL, () => {
  store.dispatch(resetStore());
});
export const logout = identity.createLogout(() => {
  clientPortalChannel.postMessage({ isSignOut: true });
});

const initTenantBranding = async () => {
  const tenantBranding = await applyTenantBranding(clientPortalApi);
  if (!tenantBranding) return;
  const { tenantId, logoUrl, name, termsAndConditions } = tenantBranding;

  store.dispatch(setTenantBranding({ tenantId, logoUrl, name, termsAndConditions }));
};

export const AuthContext = createContext({
  clientPortalUser: null as UserDto | null,
  clientPortalUserRole: null as ClientPortalRole | null,
  dataGatewayUserRole: null as DataGatewayRole | null,
  isLoggedIn: false,
});

const retrieveIdentityUser = async () => {
  try {
    const res = await identity.getCurrentUserRequest();

    store.dispatch(setIdentityUser(res.data));
    return res.data;
  } catch {
    console.log("Can't retrieve auth user");
    return null;
  }
};

export const retrieveClientPortalUser = async () => {
  try {
    const res = await getCurrentUser();

    store.dispatch(setClientPortalUser(res.data));
    return res.data;
  } catch {
    console.log("Can't retrieve current user");
    return null;
  }
};

const activateInvitedUser = async () => {
  try {
    await activateCurrentUser();
    const activeUser = await retrieveClientPortalUser();

    return activeUser;
  } catch {
    console.log("Can't activated current user");
    return null;
  }
};

const requestCompaniesByUserRole = async (user: CurrentUserResponse | null) => {
  const userRole = user?.clientPortalRole?.roleName;
  if (!userRole) return null;
  if (
    userRole === ClientPortalRole.SuperAdmin ||
    userRole === ClientPortalRole.Support ||
    userRole === ClientPortalRole.Viewer
  ) {
    await store.dispatch(fetchCompaniesAllAction());
  } else {
    await store.dispatch(fetchCompaniesByUserIdAction(user.id));
  }
};

const requestUserPermissionList = async (user: CurrentUserResponse | null) => {
  const userRole = user?.clientPortalRole?.roleName;
  if (!userRole || userRole === ClientPortalRole.SuperAdmin || userRole === ClientPortalRole.Support) return null;
  const res = await getPermissionsAssignedToUser(user.id);
  store.dispatch(setUserPermissions(res.data));
};

const fillDataByUserPermissions = async (user: CurrentUserResponse | null) => {
  try {
    if (!user) return;

    const isUserHaveAccessToTenant =
      user.tenants.find(({ domain }) => domain && domain.includes(tenantDomain)) !== undefined;
    if (!isUserHaveAccessToTenant) {
      console.log("User doesn't have access to the tenant.");
      logout();
    }

    await Promise.all([requestCompaniesByUserRole(user), requestUserPermissionList(user)]);
  } catch {
    console.log('Filling data by user permissions was failed');
  }
};

const fillClientPortalInfo = async (user: CurrentUserResponse | null) => {
  if (!user) return;
  await store.dispatch(fetchProductInfoAction());
};

const runInitialRequests = async () => {
  const identityUser = await retrieveIdentityUser();
  if (!identityUser) return;

  let currentUser = await retrieveClientPortalUser();
  if (!currentUser) return;

  if (currentUser.status === UserStatus.Invited) {
    currentUser = await activateInvitedUser();
  }
  if (!currentUser) return;

  await Promise.allSettled([fillClientPortalInfo(currentUser), fillDataByUserPermissions(currentUser)]);
};

const checkCookieAuthState = async () => {
  let cookieAuthState = identity.getCookieAuthState();
  if (cookieAuthState) {
    const isAccessTokenExpired = isTokenExpired('access', identity.getCookieAuthState());
    if (isAccessTokenExpired) {
      const isRefreshTokenExpired = isTokenExpired('refresh', identity.getCookieAuthState());
      if (isRefreshTokenExpired) {
        logout(true);
      } else {
        await identity.refreshAuthState(cookieAuthState.refreshToken, logout);
        cookieAuthState = identity.getCookieAuthState();
      }
    }
  } else {
    logout(true);
  }

  return cookieAuthState;
};

function AuthContextProvider(props: PropsWithChildren<object>) {
  const dispatch = useDispatch();
  const user = useSelector(clientPortalUserSelector);
  const clientPortalUserRole = useSelector(clientPortalUserRoleSelector);
  const dataGatewayUserRole = useSelector(dataGatewayUserRoleSelector);
  const isLoginJourneyFinished = useSelector(isLoginJourneyFinishedSelector);
  const [isInitializing, setIsInitializing] = useState(true);
  const [shouldRedirectDuringInit, setShouldRedirectDuringInit] = useState(false);

  const isLoggedIn = Boolean(user && clientPortalUserRole);

  const init = useCallback(async () => {
    setIsInitializing(true);

    const cookieAuthState = await checkCookieAuthState();

    await Promise.allSettled([
      cookieAuthState ? runInitialRequests() : null,
      isLoginJourneyFinished ? null : initTenantBranding(),
    ]);

    setIsInitializing(false);
    setShouldRedirectDuringInit(true);
  }, [isLoginJourneyFinished]);

  const broadcastChannelListener = useCallback(
    async (payload: ClientPortalBroadcastMessage) => {
      if (payload.isSignOut === true) {
        dispatch(resetStore());
        Cookie.remove(USER_AUTH_COOKIE_NAME, cookieAttributes);
      }
      if (payload.isSignIn === true) {
        await init();
      }
    },
    [dispatch, init],
  );

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      identity.checkTokenExpiration(REFRESH_TOKEN_IN_ADVANCE_TIME, logout);
    }, REFRESH_TOKEN_CHECK_TIME);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    clientPortalChannel.addEventListener('message', broadcastChannelListener);
    return () => {
      clientPortalChannel.removeEventListener('message', broadcastChannelListener);
    };
  }, [broadcastChannelListener]);

  return (
    <AuthContext.Provider
      value={{
        clientPortalUser: user,
        clientPortalUserRole,
        dataGatewayUserRole,
        isLoggedIn,
      }}
    >
      {isInitializing ? (
        <div className="container has-text-centered py-6">
          <PageLoading />
          <SignInRedirect shouldRedirect={shouldRedirectDuringInit} setShouldRedirect={setShouldRedirectDuringInit} />
        </div>
      ) : (
        <>
          {props.children}

          <ActivityDetector isLoggedIn={isLoggedIn} logout={logout} />
        </>
      )}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
