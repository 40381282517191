import { useMemo, useContext, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { RoutePath } from 'src/router';
import { AuthContext } from 'src/context/Auth';
import { ClientPortalRole, DataGatewayRole } from 'src/types';

type Props = {
  meta: {
    auth?: boolean;
    role?: {
      allowList: (ClientPortalRole | DataGatewayRole)[];
    };
  };
};

export function GuardedRoute({ meta, children }: PropsWithChildren<Props>) {
  let redirectRoute = RoutePath.root;

  const { isLoggedIn, clientPortalUserRole, dataGatewayUserRole } = useContext(AuthContext);

  const passAuthGuard = useMemo(() => {
    if (!meta.auth) return true;
    return isLoggedIn;
  }, [meta, isLoggedIn]);

  const passRolesGuard = useMemo(() => {
    if (!meta.role) return true;
    return (
      (clientPortalUserRole && meta.role.allowList.includes(clientPortalUserRole)) ||
      (dataGatewayUserRole && meta.role.allowList.includes(dataGatewayUserRole))
    );
  }, [meta, clientPortalUserRole, dataGatewayUserRole]);

  const isRouteAllowed = useMemo(() => passAuthGuard && passRolesGuard, [passAuthGuard, passRolesGuard]);

  if (passAuthGuard) {
    if (!passRolesGuard) {
      redirectRoute =
        clientPortalUserRole === ClientPortalRole.SftpOnly ? RoutePath.manageSshKey : RoutePath.permissionDenied;
    }
  } else {
    redirectRoute = RoutePath.login;
  }

  return <>{isRouteAllowed ? children : <Navigate to={redirectRoute} />}</>;
}
