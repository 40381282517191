import { useContext } from 'react';

import { AuthContext } from 'src/context/Auth';

import UserNavbarItem from './UserNavbarItem';

function Header() {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <>
      <header className="has-background-white">
        <nav className="navbar is-flex is-justify-content-flex-end" role="navigation" aria-label="main navigation">
          {isLoggedIn && <UserNavbarItem />}
        </nav>
      </header>
    </>
  );
}

export default Header;
