import { Suspense, lazy, useState, useContext, useLayoutEffect } from 'react';
import { Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { RoutePath, GuardedRoute, history } from 'src/router';

import AuthContextProvider, { AuthContext } from 'src/context/Auth';

import store from 'src/store';

import { Loading } from '@itm/shared-frontend/lib/components';

import Layout from 'src/components/layout/Layout';
import BreadcrumbsTrail from 'src/components/BreadcrumbsTrail';
import usePreviousLocationInStore from 'src/hooks/usePreviousLocationInStore';

import AuthRoutes from 'src/pages/auth';
import PermissionDeniedPage from 'src/pages/permissionDenied';
import DashboardPage from 'src/pages/dashboard';

import { ClientPortalRole } from 'src/types';

const AdministrationRoutes = lazy(() => import('src/pages/administration'));
const DataRoutes = lazy(() => import('src/pages/data'));
const ConfigurationRoutes = lazy(() => import('src/pages/configuration'));

function RootRoutesFallback() {
  return (
    <Layout>
      <Loading isLoading />
    </Layout>
  );
}

function RootRoutes() {
  const { isLoggedIn, clientPortalUserRole } = useContext(AuthContext);
  const redirectRouteByRole =
    clientPortalUserRole === ClientPortalRole.SuperAdmin ||
    clientPortalUserRole === ClientPortalRole.Support ||
    clientPortalUserRole === ClientPortalRole.ClientAdmin ||
    clientPortalUserRole === ClientPortalRole.Analyst ||
    clientPortalUserRole === ClientPortalRole.Viewer ||
    clientPortalUserRole === ClientPortalRole.Manager
      ? RoutePath.dashboard
      : RoutePath.manageSshKey;

  usePreviousLocationInStore();

  return (
    <Suspense fallback={<RootRoutesFallback />}>
      <Routes>
        <Route index element={<Navigate to={isLoggedIn ? redirectRouteByRole : RoutePath.login} replace={true} />} />

        {/* Auth */}
        <Route path={`${RoutePath.authRoot}/*`} element={<AuthRoutes />} />

        {/* With Layout */}
        <Route
          path="*"
          element={
            <Layout>
              <BreadcrumbsTrail />

              <Routes>
                <Route path={RoutePath.permissionDenied} element={<PermissionDeniedPage />} />
                <Route
                  path={`${RoutePath.dashboard}/*`}
                  element={
                    <GuardedRoute
                      meta={{
                        auth: true,
                        role: {
                          allowList: [
                            ClientPortalRole.SuperAdmin,
                            ClientPortalRole.Support,
                            ClientPortalRole.ClientAdmin,
                            ClientPortalRole.Analyst,
                            ClientPortalRole.Viewer,
                            ClientPortalRole.Manager,
                          ],
                        },
                      }}
                    >
                      <DashboardPage />
                    </GuardedRoute>
                  }
                />
                <Route
                  path={`${RoutePath.dataRoot}/*`}
                  element={
                    <GuardedRoute
                      meta={{
                        auth: true,
                        role: {
                          allowList: [
                            ClientPortalRole.SuperAdmin,
                            ClientPortalRole.Support,
                            ClientPortalRole.ClientAdmin,
                            ClientPortalRole.Analyst,
                          ],
                        },
                      }}
                    >
                      <DataRoutes />
                    </GuardedRoute>
                  }
                />
                <Route
                  path={`${RoutePath.configurationRoot}/*`}
                  element={
                    <GuardedRoute
                      meta={{
                        auth: true,
                        role: {
                          allowList: [
                            ClientPortalRole.SuperAdmin,
                            ClientPortalRole.Support,
                            ClientPortalRole.ClientAdmin,
                            ClientPortalRole.Analyst,
                          ],
                        },
                      }}
                    >
                      <ConfigurationRoutes />
                    </GuardedRoute>
                  }
                />
                <Route
                  path={`${RoutePath.administrationRoot}/*`}
                  element={
                    <GuardedRoute
                      meta={{
                        auth: true,
                        role: {
                          allowList: [
                            ClientPortalRole.SuperAdmin,
                            ClientPortalRole.Support,
                            ClientPortalRole.ClientAdmin,
                            ClientPortalRole.Analyst,
                            ClientPortalRole.Viewer,
                          ],
                        },
                      }}
                    >
                      <AdministrationRoutes />
                    </GuardedRoute>
                  }
                />
                <Route path="*" element={<Navigate to={RoutePath.root} />} />
              </Routes>
            </Layout>
          }
        ></Route>

        <Route path="*" element={<Navigate to={RoutePath.root} />} />
      </Routes>
    </Suspense>
  );
}

function RootRouter() {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });
  useLayoutEffect(() => history.listen(setState), []);
  return (
    <Router location={state.location} navigationType={state.action} navigator={history}>
      <Provider store={store}>
        <AuthContextProvider>
          <RootRoutes />
        </AuthContextProvider>
      </Provider>
    </Router>
  );
}

export default RootRouter;
